/* eslint-disable @typescript-eslint/no-use-before-define */

import React, { useEffect, useRef, useState } from 'react';
import { useQueryParam, StringParam } from 'use-query-params';
import isEmpty from 'lodash/isEmpty';
import { PolicyList } from '@components/Policies/PolicyList';
import { AirlineCarrier } from 'types/contentful';
import { MainMenu } from '@components/MainMenu';
import { PolicyCardsSearch } from '@components/Policies/PolicyCardsSearch';
import { NoCardMatchesFoundMessage } from '@components/NoCardMatchesFoundMessage';
import SortBar from '@components/SortBar';
import { SuppliersCaveat } from '@components/SuppliersCaveat';
import { InlineContainer } from '@components/InlineContainer';

import { Item } from '@components/SortBar/types';
import { getPolicyCardsSearchData } from '@components/Policies/PolicyCardsSearch/helpers';
import { IPageProps } from '../types';
import { getPolicyListItems } from './helpers';

const sortByItem: Item[] = [
  {
    label: 'Alphabetically',
    fieldName: 'title',
  },
  {
    label: 'Last updated',
    fieldName: 'updatedAt',
  },
];

const PolicyAirlineCarriersPage = (props: IPageProps<AirlineCarrier[]>): React.ReactElement => {
  const {
    uri,
    navigate,
    pageContext,
    location: { pathname, hash, search },
  } = props;

  const [filterData, setFilterData] = useState<AirlineCarrier[]>([]);
  const [emptyItemsMessageVisibile, setEmptyItemsMessageVisibile] = useState(false);
  const [sortBySelectedOption, setSortBySelectedOption] = useState(sortByItem[0]);

  const ref = useRef<HTMLDivElement | null>(null);

  const [code, setCode] = useQueryParam('code', StringParam);

  useEffect(() => scrollToItem(ref, hash), [hash]);

  const displayFilterData = (data: AirlineCarrier[]): void => {
    setEmptyItemsMessageVisibile(false);
    setFilterData(data);
  };

  const handleSortSelect = (data: AirlineCarrier[], selectedOption: Item): void => {
    setSortBySelectedOption(selectedOption);
    if (!emptyItemsMessageVisibile) {
      displayFilterData(data);
    }
  };

  const handleSearchChange = (data: AirlineCarrier[], isResetData: boolean): void => {
    if (isResetData) {
      displayFilterData(pageContext.data);

      return;
    }

    if (isEmpty(data)) {
      setEmptyItemsMessageVisibile(true);
    } else {
      displayFilterData(data);
    }
  };

  const data = !isEmpty(filterData) ? filterData : pageContext.data;

  useEffect(() => {
    if (code) {
      props.navigate(`${pathname.replace(/\/$/, '')}/${search}`);

      handleSearchChange(
        getPolicyCardsSearchData({
          data,
          value: code,
          searchFields: ['airlineCodeIata'],
          filterByFieldName: 'title',
        }),
        false,
      );
    }
  }, [code]);

  return (
    <>
      <MainMenu uri={uri} navigate={navigate} staticUri={pathname} />
      <InlineContainer>
        <PolicyCardsSearch
          handleValueChange={handleSearchChange}
          data={pageContext.data}
          searchFields={['title', 'airlineCodeIata']}
          filterByFieldName="title"
          setCode={setCode}
        />
        <SortBar
          data={data}
          options={sortByItem}
          handleSortSelect={handleSortSelect}
          selectedOption={sortBySelectedOption}
        />
        <SuppliersCaveat />
      </InlineContainer>
      <div ref={ref}>
        {emptyItemsMessageVisibile ? (
          <NoCardMatchesFoundMessage />
        ) : (
          <PolicyList getItemProps={getPolicyListItems} data={data} />
        )}
      </div>
    </>
  );
};

function scrollToItem(ref: React.MutableRefObject<HTMLDivElement | null>, hash: string) {
  setTimeout(() => {
    if (!hash.includes('#') || !ref.current) {
      return;
    }
    const cardItem = ref.current.querySelector(hash);
    if (cardItem) {
      const coords = cardItem.getBoundingClientRect();
      // offset to display header of airline because it is overlaped by page header
      const offset = 70;
      window.scrollBy({ top: coords.top - offset, left: 0 });
    }
  }, 200);
}

export default PolicyAirlineCarriersPage;
