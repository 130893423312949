import { AirlineCarrier } from 'types/contentful';
import { PolicyCard } from '@components/Policies/PolicyCard';

export const getPolicyListItems = (item: AirlineCarrier): Parameters<typeof PolicyCard>[0] => ({
  items: [
    {
      content: item.faceMasks?.json,
      title: 'Face Masks',
      classImg: 'faceMasksImg',
    },
    {
      content: item.healthChecks?.json,
      title: 'Health Information',
      classImg: 'healthChecksImg',
    },
    {
      content: item.socialDistancing?.json,
      title: 'Social Distancing',
      classImg: 'socialDistancingImg',
    },
    {
      content: item.onBoard?.json,
      title: 'On Board',
      classImg: 'onboardImg',
    },
    {
      content: item.loungeAccess?.json,
      title: 'Lounge Access',
      classImg: 'loungeAccessImg',
    },
    {
      content: item.otherInformation?.json,
      title: 'Other Information',
      classImg: 'otherInformationImg',
    },
  ],
  title: item.title,
  notInService: item.notInService,
  updatedAt: item.updatedAt,
  logoUrl: item.logoImage?.file.url,
  digitalHealthPass: item.digitalHealthPass,
  link: item.airlineCarrierUrl
    ? {
        url: item.airlineCarrierUrl.link,
        label: item.airlineCarrierUrl.label,
      }
    : undefined,
  key: item.id,
  id: `id${item.id}`,
});
